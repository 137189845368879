var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"id":"app-bar","absolute":"","app":"","color":"transparent","flat":"","height":"75"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"elevation":"1","fab":"","small":""},on:{"click":function($event){_vm.$vuetify.breakpoint.smAndDown
        ? _vm.setDrawer(!_vm.drawer)
        : _vm.$emit('input', !_vm.value)}}},[(_vm.value)?_c('v-icon',[_vm._v(" mdi-view-quilt ")]):_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1),_c('v-toolbar-title',{staticClass:"hidden-sm-and-down font-weight-light",domProps:{"textContent":_vm._s(_vm.$route.name)}}),_c('v-spacer'),_c('div',{staticClass:"mx-3"}),_c('v-btn',{staticClass:"ml-2",attrs:{"min-width":"0","text":""}},[_c('v-icon',[_vm._v("mdi-home-account")]),_c('strong',{staticClass:"display-1 text-dark"},[_vm._v(" "+_vm._s(_vm.currentUser.FirstName)+"'s Goose ")])],1),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var attrs = ref.attrs;
        var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"content":_vm.notificationCount,"value":_vm.notificationCount,"color":"red","overlap":"","bordered":""}},[_c('v-icon',[_vm._v("mdi-bell")])],1)],1)]}}])},[_c('v-list',{attrs:{"tile":false,"nav":""}},[_c('div',_vm._l((_vm.notifications),function(n,i){return _c('app-bar-item',{key:("item-" + i),attrs:{"two-line":""}},[_c('v-list-item-content',{on:{"click":function($event){return _vm.gotoMessage(n.id)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(n.business_profile.company_name)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(
                n.room.timeline[n.room.timeline.length - 1].event.content.body
              )}})],1)],1)}),1)])],1),_c('v-menu',{attrs:{"bottom":"","left":"","min-width":"200","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var attrs = ref.attrs;
              var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}])},[_c('v-list',{attrs:{"tile":false,"flat":"","nav":""}},[_vm._l((_vm.profile),function(p,i){return [(p.divider)?_c('v-divider',{key:("divider-" + i),staticClass:"mb-2 mt-2"}):_c('app-bar-item',{key:("item-" + i),attrs:{"href":"/account"}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(p.title)}})],1)]}),_c('div',{on:{"click":_vm.onLogout}},[_c('app-bar-item',{on:{"click":_vm.onLogout}},[_c('v-list-item-title',{on:{"click":_vm.onLogout}},[_vm._v(" Log OUT ")])],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }