<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="
        $vuetify.breakpoint.smAndDown
          ? setDrawer(!drawer)
          : $emit('input', !value)
      "
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />



    <div class="mx-3" />

    <v-btn class="ml-2" min-width="0" text>
      <v-icon>mdi-home-account</v-icon>
      <strong class="display-1 text-dark">
        {{ currentUser.FirstName }}'s Goose
      </strong>
    </v-btn>

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template #activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-badge
            :content="notificationCount"
            :value="notificationCount"
            color="red"
            overlap
            bordered
          >
            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div>
          <app-bar-item
            v-for="(n, i) in notifications"
            :key="`item-${i}`"
            two-line
          >
            <v-list-item-content @click="gotoMessage(n.id)">
              <v-list-item-title v-text="n.business_profile.company_name" />
              <v-list-item-subtitle
                v-text="
                  n.room.timeline[n.room.timeline.length - 1].event.content.body
                "
              ></v-list-item-subtitle>
            </v-list-item-content>
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>

    <v-menu
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template #activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list :tile="false" flat nav>
        <template v-for="(p, i) in profile">
          <v-divider v-if="p.divider" :key="`divider-${i}`" class="mb-2 mt-2" />

          <app-bar-item v-else :key="`item-${i}`" href="/account">
            <v-list-item-title v-text="p.title" />
          </app-bar-item>
        </template>

        <div @click="onLogout">
          <app-bar-item @click="onLogout">
            <v-list-item-title @click="onLogout"> Log OUT </v-list-item-title>
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from "vuetify/lib";
import { LOGOUT } from "@/core/services/store/auth.module";
import GetNotificationCompanies from "@/graphql/queries/GetNotificationCompanies.gql";
import GetCompanyChatInfo from "@/graphql/queries/GetCompanyChatInfo.gql";
// Utilities
import { mapGetters, mapState, mapMutations } from "vuex";

export default {
  name: "DashboardCoreAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    profile: [{ title: "Edit Profile", path: "/account" }, { divider: true }],
  }),
  computed: {
    ...mapGetters(["isAuthenticated", "currentUser", "roomsByAlias"]),
    ...mapState(["drawer"]),
    notificationCount() {
      return Object.values(this.roomsByAlias)
        .map((r) => r._notificationCounts.total)
        .reduce((a, c) => a + c, 0);
    },
    notificationCompanyIds() {
      const companyIds = Object.keys(this.roomsByAlias)
        .filter((k) => this.roomsByAlias[k]._notificationCounts.total > 0)
        // just get company identifier from the room alias
        .map((k) => k.split("<")[0].split("#")[1]);
      return companyIds;
    },
    notifications() {
      if (!this.companies) {
        return [];
      }
      return this.companies.map((c) => ({
        ...c,
        room: this.roomsByAlias[
          "#" +
            c.identifier +
            "<=>" +
            this.userCompany.identifier +
            ":" +
            process.env.VUE_APP_MATRIX_URL_BASE_DOMAIN
        ],
      }));
    },
  },

  apollo: {
    companies: {
      query: GetNotificationCompanies,
      variables() {
        return {
          companyIds:
            this.notificationCompanyIds.length === 0
              ? null
              : this.notificationCompanyIds,
        };
      },
    },
    userCompany: {
      query: GetCompanyChatInfo,
      variables() {
        return {
          id: this.$store.state.auth.user.company_relation,
        };
      },
      skip() {
        return (
          !this.$store.state.auth.user ||
          !this.$store.state.auth.user.company_relation
        );
      },
      update: (data) => data.company,
    },
  },
  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
    gotoMessage(id) {
      this.$router.push("/supplier/messaging/thread/" + id);
    },
    async onLogout() {
      await this.$store.dispatch(LOGOUT);
      this.$router.push({ path: "/pages/login" });
    },
  },
};
</script>
